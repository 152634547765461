
import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import Button from '../styledButton';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import { client } from '../../apollo/client';
import gql from 'graphql-tag';
import { LabelMedium } from 'baseui/typography';
import { Slider } from 'baseui/slider';
import Step from "../connect/step";

import { useStyletron } from 'baseui';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import {
  LabelSmall, LabelXSmall
} from 'baseui/typography';
import ChevronDown from 'baseui/icon/chevron-down';
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";

import {
  Checkbox,
  STYLE_TYPE,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import store from '../../redux/store';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { PLACEMENT } from 'baseui/toast';
import SVGDevice from './svgDevice';
import cookieTest from './builderUtils/helpers';




const DEVICES = [
  {
    display: 'Mobile',
    width: 1170,
    height: 2532
  },
  {
    display: 'Tablet',
    width: 2388,
    height: 1668
  },
  {
    display: 'Desktop',
    width: 2880,
    height: 1800
  }
];


const StepNavHeaderText = styled(LabelSmall)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DeviceButton = styled.div`
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`


const svgStyle = {
  height: '24px',
  position: 'relative',
  margin: '0 auto 5px',
  display: 'block',
};


const PreviewFromPwa = ({
  title, subtitle, channel_id, deck_id, card_id, organization_id, preview_type = 'back', hideOptions, add_open_in_new_tab, menu = {}, visibleHeader, data, category_id
}) => {
  const [device, setDevice] = React.useState(0);
  const [zoom, setZoom] = React.useState(3);
  const defaultTheme = store.getState().appBasicControls.theme || 'black';
  const width = DEVICES[device].width / zoom;
  const height = DEVICES[device].height / zoom;
  const [css, theme] = useStyletron();
  const [url, setUrl] = React.useState('');
  // const color = selected ? 'white' : theme.colors.primaryC;
  // const textColor = selected ? 'white' : theme.colors.primaryA;
  // console.log('datadatadata', data, rest);
  React.useEffect(() => {
    client.query({
      query: gql`
        query organization_pwa_app {
          organization_pwa_app(organization_id: "${organization_id}") {
            app_url
          }
        }
      `
    }).then((response) => {
      try {

        // checkThirdPartyCookies(appUrl)
        let appUrl = response.data.organization_pwa_app[response.data.organization_pwa_app.length - 1].app_url;
        const previewLink = getPreviewLink(appUrl);
        setUrl(previewLink)
      } catch (e) {

      }
    })
  }, [channel_id, category_id, deck_id, card_id]);

  const checkThirdPartyCookies = (appUrl) => {
    cookieTest(appUrl, (data) => {
      console.log('cookieTest result', data
      )
    })
  }

  const getPreviewLink = (appUrl) => {
    try {
      const categoryId = category_id || data.card?.card_decks[0]?.deck_category[0].category_id
      if (preview_type == 'front') {
        return `${appUrl}/channel/${channel_id}/category/${categoryId}/deck/${deck_id}/card/front/web`
      } else {
        return `${appUrl}/channel/${channel_id}/category/${categoryId}/deck/${deck_id}/card/${card_id}/back-web`
      }
    } catch (e) {
      console.log('getPreviewLink error', e)
      return '';
    }
  }


  const getPreviewPageLink = () => {
    try {
      const categoryId = category_id || data.card?.card_decks[0]?.deck_category[0].category_id
      let link = `${window.location.origin}/preview/${organization_id}/category/${categoryId}/channel/${channel_id}/deck/${deck_id}/card/${card_id}/preview/${preview_type}`
      return link;
    } catch (e) {
      console.log('getPreviewLink error', e)
      return '';
    }
  }

  const onMenuOptionClick = React.useMemo(() => ({ item }) => menu.onOptionClick(item), [menu.options]);

  const reloadIframe = () => {
    let backup = url;
    setUrl('');
    setTimeout(() => {
      setUrl(backup);
    }, 500);
  };

  const onDeviceSizeClick = (e, index) => {
    e.preventDefault();
    setDevice(index);
  }


  // const url = 'https://cardware-web-app-glastonbury.netlify.app/app/channel/7846f6a4-2a6a-4b82-bcab-66727f9ebcd8';
  console.log('preview from pwa', { title, subtitle, channel_id, deck_id, card_id, organization_id, preview_type, hideOptions, add_open_in_new_tab, menu, visibleHeader, data, category_id })
  console.log('appurlappurl', url);
  console.log(url);
  console.log(zoom);
  return <div style={{
    display: 'flex',
    flexDirection: 'column'
  }}>

    {!hideOptions && visibleHeader && <div
      style={{
        height: `72px`,
        background: defaultTheme === "dark" ? 'black' : 'white', // theme.colors.builderNavigationBackground,
        width: '100vw',
        boxShadow: theme.lighting.builderNavigationShadow,
        marginBottom: '50px',
      }}
    >

      <div style={{
        position: 'relative',
        float: 'left',
        width: '300px',
        height: '100%',
        borderRight: '2px solid #EEEEEE'
      }}>
        <div style={{
          position: 'relative',
          float: 'left',
          width: 'auto', height: 'auto', paddingLeft: '24px',
          top: '50%',
          transform: 'translateY(-50%)'
        }}>
          <div style={{ display: 'flex' }}>
            <StatefulPopover
              content={() => (
                <div
                  className={css({
                    padding: '16px',
                    maxWidth: '400px',
                    fontSize: '14px',
                  })}
                >
                  {title}
                </div>
              )}
              triggerType={TRIGGER_TYPE.hover}
              accessibilityType={'tooltip'}
              showArrow
              placement={PLACEMENT.bottomRight}
              overrides={{
                Body: {
                  style: {
                    zIndex: 100,
                  }
                }
              }}
            >
              <StepNavHeaderText>{title}</StepNavHeaderText>
            </StatefulPopover>
            {menu && menu.isActive && <StatefulPopover
              content={() => (
                <StatefulMenu
                  items={menu.options}
                  onItemSelect={onMenuOptionClick}
                  noResultsMsg={menu.noResultsMsg}
                />
              )}
              returnFocus
              renderAll
              autoFocus
            >
              <ChevronDown size={20} style={{ marginLeft: '2px', cursor: 'pointer' }} />
            </StatefulPopover>}
          </div>
          <LabelXSmall style={{ marginTop: '0px', fontWeight: '400' }}>{subtitle}</LabelXSmall>
        </div>
      </div>

      <div style={{
        position: 'relative',
        float: 'left',
        height: '100%'
      }}>
        {DEVICES.map((d, index) => {
          const onClick = (e) => { onDeviceSizeClick(e, index) }
          return (
            <DeviceButton key={index} isSelected={index == device} style={{ height: '100%', borderRadius: '0', margin: '0', borderRight: '2px solid #EEEEEE', width: '100px', background: index == device ? '#7A5AF8' : '#fff' }} onClick={onClick}>
              <LabelMedium style={{
                color: index == device ? 'white' : 'black'
              }}>
                <SVGDevice svgStyle={svgStyle} index={index} device={device} />{d.display}</LabelMedium>
            </DeviceButton>
          )
        })}

      </div>

      {/* Published and Exit Button */}
      <div style={{
        position: 'relative',
        float: 'right',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        width: '100px',
        borderLeft: '2px solid #EEEEEE',
        justifyContent: 'center',
      }}>
        <div
          onClick={() => {
            reloadIframe();
          }}
          style={{
            cursor: 'pointer',
            backgroundColor: 'white',
            color: '#000',
            fontSize: '16px',
            fontWeight: '500',
          }}>
          <svg style={svgStyle} width="24" height="16" viewBox="0 0 24 24" fill="#000"><path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"></path></svg>
          Refresh
        </div>
      </div>
    </div>
    }


    {/* {add_open_in_new_tab && <Button height={'48px'} isSelected onClick={() => {
            window.open(
              `${window.location.origin}/preview/${organization_id}/channel/${channel_id}/deck/${deck_id}/card/${card_id}/preview/${preview_type}`,
              '_blank');
          }}>
            <LabelMedium style={{
              color: 'white'
            }}>Preview in new Tab</LabelMedium>
          </Button>}
        </div>
      </div>
    </Cell>} */}
    <Cell span={hideOptions ? 12 : 24}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%'
        }}
      >
        <div class="resp-container" style={{
          position: 'relative',
          width,
          height,
          float: 'left'
        }}>


          {add_open_in_new_tab && <div
            onClick={() => {
              const link = getPreviewPageLink();
              console.log('link', link);
              window.open(
                link,
                '_blank');
            }}
            style={{
              position: 'absolute',
              top: 12,
              right: 12,
              cursor: 'pointer',
              zIndex: 5,
              backgroundColor: 'white',
              padding: '8px',
              borderRadius: '4px',
              height: '40px'
            }}>
            <img src="https://img.icons8.com/pastel-glyph/64/000000/external-link--v1.png" style={{
              marginBottom: '0px',
              height: '24px',
            }} />
          </div>}


          <iframe className="resp-iframe" src={url} gesture="media" allow="encrypted-media" allowfullscreen></iframe>



        </div>
      </div>

      <div style={{
        margin: '34px auto 0px',
        width: '400px'
      }}>
        <Slider
          value={[20 * zoom]}
          step={20}
          marks
          onChange={({ value }) => setZoom(value / 20)}
        />
      </div>
    </Cell>
  </div>;
};

export default PreviewFromPwa;